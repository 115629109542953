<template>
    <div class="top mb-5">
        <b-container>
            <b-row align-h="center">
                <b-col cols="12" class="mb-4">
                    <div>
                        <b-nav tabs>
                            <b-nav-item class="nav-item" active-class="active" to="/admin/main">Hem</b-nav-item>
                            <b-nav-item class="nav-item" active-class="active" to="/admin/references">Referenser</b-nav-item>
                            <b-nav-item-dropdown text="Våra tjänster">
                                <b-dropdown-item to="/admin/commercial">Kommersiell</b-dropdown-item>
                                <b-dropdown-item to="/admin/private">Privat</b-dropdown-item>
                                <b-dropdown-item to="/admin/maintenance">Fastighetsskötsel</b-dropdown-item>
                            </b-nav-item-dropdown>
                            <b-nav-item class="nav-item" active-class="active" to="/admin/about">Om Oss</b-nav-item>
                            <b-nav-item class="nav-item" active-class="active" to="/admin/contact">Kontakta Oss</b-nav-item>
                            <b-nav-item class="nav-item" active-class="active" to="/admin/change-password">Change Password</b-nav-item>
                            <b-nav-item to="/login">Logout</b-nav-item>
                        </b-nav>
                    </div>
                </b-col>
                <b-col cols="12">
                    <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
                </b-col>

                <b-col cols="12" class="mb-4">
                    <router-view></router-view>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import axios from "axios";
    import config from 'config';

    export default {
        name: "Admin",
        components: {},
        data () {
            return {
            }
        },
        computed: {
            alert () {
                return this.$store.state.alert;
            }
        },
        created() {
            // clear errors
            this.$store.dispatch('alert/clear');
        },
        mounted () {
            const loggedIn = localStorage.getItem('user');

            if (!loggedIn || JSON.parse(loggedIn) == null) {
                return this.$router.push('/login');
            }

            window.scrollTo(0, 0);
            this.checkSession();
        },
        methods: {
            checkSession () {
                axios.post( `${config.apiUrl}/login/token`,
                    {},
                    {
                        headers: {
                            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                        }
                    }
                ).catch((e) => {
                    if (e.response) {
                        console.log(e.response.status)
                        if (e.response.status === 403) {
                            this.$store.dispatch('alert/error', 'Session expired. Please login again.', {root: true});
                            this.$router.push('/login');
                        } else if (e.response.status === 502) {
                            this.$store.dispatch('alert/error', 'Server error.', {root: true});
                        } else {
                            this.$store.dispatch('alert/error', e.response, { root: true });
                        }
                    }
                })
            }
        }
    };
</script>

<style scoped>
    .top {
        margin-top: 100px;
    }
</style>
